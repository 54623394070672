@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';

.queueBlock {
  &_content {
    margin-left: size-rem(80px);
    margin-right: size-rem(80px);
  }

  &_contentTitle {
    font-family: $main-font-family;
    font-size: size-rem(85px);
    font-style: normal;
    font-weight: 350;
    line-height: 95%;
    letter-spacing: size-rem(-1.7px);
    margin-bottom: size-rem(41px);

    @include vp--740 {
      font-size: size-rem(40px, true);
      margin-bottom: 0;
    }
  }

  &_contentDescription {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: size-rem(127px);
    @include vp--740 {
      grid-template-columns: 1fr;
      grid-gap: size-rem(40px, true);
    }
  }

  &_firstQueueImage {
    width: size-rem(773px);

    @include vp--740 {
      width: 100%;
    }
  }
  &_secondQueueImage {
    width: size-rem(880px);

    @include vp--740 {
      width: 100%;
      margin-top: size-rem(40px, true);
    }
  }

  &_firstQueueRightImage {
    width: size-rem(353px);
    margin-top: size-rem(96px);
    margin-bottom: size-rem(186px);

    @include vp--740 {
      width: 100%;
      margin: size-rem(40px, true) auto size-rem(47px, true);
      max-width: size-rem(265px, true);
    }
  }

  &_secondQueueRightImage {
    position: relative;
    z-index: 1;
    width: size-rem(270px);
    margin-top: size-rem(150px);
    margin-bottom: size-rem(112px);
    left: size-rem(320px);

    @include vp--740 {
      position: center;
      margin: 0 auto;
      left: 0;
      width: size-rem(265px, true);
    }
  }

  &_leftBlock {
    @include vp--740 {
      width: 100%;
      margin-top: size-rem(40px, true);
    }
  }

  &_rightBlock {
    @include vp--740 {
      width: 100%;
    }
  }
  &_firstText {
    font-family: $primary-font-family;
    font-size: size-rem(18px);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: size-rem(-0.36px);
    width: size-rem(413px);

    @include vp--740 {
      font-family: $primary-font-family;
      font-size: size-rem(18px, true);
      line-height: 140%;
      width: 100%;
    }
  }

  &_listDescription {
    margin-top: size-rem(50px);
    @include vp--740 {
      margin-top: size-rem(31px, true);
    }

    li {
      margin-top: size-rem(16px);
      font-family: $main-font-family;
      font-size: size-rem(20px);
      font-style: normal;
      font-weight: 350;
      line-height: 130%;
      padding-left: size-rem(4px);
      margin-left: size-rem(16px);

      @include vp--740 {
        margin-top: size-rem(28px, true);
        font-size: size-rem(18px, true);
        padding-left: size-rem(4px, true);
        margin-left: size-rem(16px, true);
      }
    }
  }
  &_rightBlockFooterdescription {
    color: var(--grey-2, #979797);
    font-family: $primary-font-family;
    font-size: size-rem(18px);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: size-rem(-0.36px);

    &_smallImageMob {
      @include vp--740 {
        position: absolute;
        bottom: size-rem(-148px, true);
      }
    }

    @include vp--740 {
      font-size: size-rem(18px, true);
      font-weight: 400;
      text-align: left;
    }
  }
}

.queueBlock_SecondBlock {
  margin-top: size-rem(140px);
  position: relative;

  @include vp--740 {
    margin-top: size-rem(60px, true);
  }

  .queueBlock_contentTitle {
    margin-bottom: size-rem(28px);
    @include vp--740 {
      margin-bottom: size-rem(40px, true);
    }
  }

  .queueBlock_Second {
    display: grid;
    grid-auto-flow: column;
    gap: 0;

    @include vp--740 {
      grid-auto-flow: inherit;
    }

    div:nth-child(2) {
      order: -1;
    }
  }
}

.headerBlock {
  display: flex;
  justify-content: center;
}

.titleBlock {
  margin-top: size-rem(80px);
  margin-bottom: size-rem(100px);
  display: flex;
  justify-content: center;
  vertical-align: center;
  align-items: center;
  width: size-rem(665px);
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index: 1;

  @include vp--740 {
    width: size-rem(335px, true);
    @include vp--740 {
      margin-top: size-rem(60px, true);
      margin-bottom: size-rem(60px, true);
    }
  }

  &_title {
    font-size: size-rem(85px);
    font-style: normal;
    font-weight: 350;
    line-height: 95%;
    letter-spacing: size-rem(-1.7px);
    font-family: $main-font-family;

    @include vp--740 {
      font-size: size-rem(40px, true);
      letter-spacing: size-rem(-0.8px, true);
    }
  }

  &_description {
    margin-top: size-rem(50px);
    font-family: $main-font-family;
    font-size: size-rem(20px);
    font-style: normal;
    font-weight: 350;
    line-height: 130%;

    @include vp--740 {
      font-size: size-rem(16px, true);
      margin-top: size-rem(24px, true);
    }
  }
}

.general {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: size-rem(50px);
  padding-bottom: size-rem(160px);

  @include vp--740 {
    gap: size-rem(24px, true);
    padding-bottom: size-rem(60px, true);
  }

  &__title {
    font-family: $main-font-family;
    font-size: size-rem(85px);
    font-weight: 350;
    line-height: siez-rem(81px);
    letter-spacing: -0.02em;
    text-align: center;

    @include vp--740 {
      font-family: $main-font-family;
      font-size: size-rem(40px, true);
      font-weight: 350;
      line-height: size-rem(38px, true);
      letter-spacing: -0.02em;
      text-align: center;
    }
  }

  &__description {
    font-family: $main-font-family;
    font-size: size-rem(20px);
    font-weight: 350;
    line-height: size-rem(26px);
    letter-spacing: 0em;
    text-align: center;
    width: size-rem(574px);

    @include vp--740 {
      font-family: $main-font-family;
      font-size: size-rem(16px, true);
      font-weight: 350;
      line-height: size-rem(21px, true);
      letter-spacing: 0em;
      text-align: center;
      width: 100%;
      padding: 0 5%;
    }
  }
}

.aboutDesktop {
  position: absolute;
  z-index: 0 !important;
  top: size-rem(-120px);
  left: 0;
  width: 100vw;
  height: size-rem(2986px);
  flex-shrink: 0;
}
.aboutDesktopSvg {
  width: size-rem(1596.708px);
  height: size-rem(2986px);
  position: relative;
  z-index: 0 !important;
  top: size-rem(120px);
}

.aboutMobile {
  position: absolute;
  z-index: 0;
  top: size-rem(250px, true);
  right: size-rem(-26px, true);
  height: size-rem(961.044px, true);
  width: size-rem(200.689px, true);
  flex-shrink: 0;
}
.aboutMobileSvg {
  height: size-rem(961.044px, true);
  width: size-rem(200.689px, true);
}
.aboutMobileQueue {
  position: absolute;
  z-index: 0;
  bottom: size-rem(160px, true);
  right: 0;
  height: size-rem(737.666px, true);
  width: size-rem(400.689px, true);
  flex-shrink: 0;
}

.aboutMobileQueueSvg {
  height: size-rem(737.666px, true);
  width: size-rem(400.689px, true);

  // & > path {
  //   stroke-dashoffset: 0;
  // }
}
