@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';

.fifthScreenContainer {
  width: 100%;
  position: relative;
  padding-top: size-rem(140px);
  height: size-rem(3008px);
  background-color: $clr-light-grey;
  overflow: hidden;
  @include vp--740 {
    min-height: size-rem(1000px, true);
    padding-top: size-rem(60px, true);
    height: size-rem(3120px, true);
  }
}
